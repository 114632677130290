import React from 'react';
import {Navigate } from 'react-router-dom';

const UnProtectedRouteAdmin = ({
    user,
    redirectPath = '/dashboard',
    children
})=>{
    if (user === undefined || user === null) {
        return children;
    }

    return <Navigate to={redirectPath} replace />;
}
export default UnProtectedRouteAdmin;

import React, {Suspense, useEffect} from 'react';
import { Routes, Route} from 'react-router-dom';
import Cookies from 'js-cookie';
import ScrollToTop from './components/ScrollTop';
import { useSelector, useDispatch } from 'react-redux';
import {cartTotals} from './redux/cartSlice'
import ProtectedRoute from './config/protected';
import ProtectedRouteAdmin from './config/protectadmin';
import UnProtectedRoute from './config/unprotected';
import UnProtectedRouteAdmin from './config/unprotectadmin';
// const q = query(collection(db, "e-products"), where("stock", "==", "20")); 
// const querySnapshot = await getDocs(q);
// const q = query(collection(db, "e-products"), limit(10)); // Limitează la primele 10 produse 
// const querySnapshot = await getDocs(q);

//Public Pages
const Homepage = React.lazy(() => import('./pages/Homepage'));
const Products = React.lazy(() => import('./pages/Products'));
const Product = React.lazy(()=> import('./pages/Product'));
const SearchResults = React.lazy(()=> import('./pages/SearchResults'));
const Category = React.lazy(()=> import('./pages/Category'));
const ThanYouPage = React.lazy(()=> import('./pages/ThanYouPage'));
const Contact = React.lazy(()=> import('./pages/Contact'));
const Checkout_Cart = React.lazy(()=> import('./pages/Checkout_Cart'));
const AuthForm = React.lazy(()=> import('./pages/AuthForm'));
const SeeOrder = React.lazy(()=> import('./pages/SeeOrder'));
const NoPage  = React.lazy(()=> import('./pages/NoPage'));
const CatList = React.lazy(() => import('./pages/CatList'));
// Auth User
const Account  = React.lazy(()=> import('./auth/Account'));
const Orders  = React.lazy(()=> import('./auth/Orders'));
//Adminstrator
const Dashboard = React.lazy(()=> import('./admin/Dashboard'));
const CreateProduct = React.lazy(()=> import('./admin/CreateProduct'));
const EditOrder = React.lazy(()=> import('./admin/EditOrder'));
const EditProduct = React.lazy(()=> import('./admin/EditProduct'));
const GeneralSettings = React.lazy(()=> import('./admin/GeneralSettings'));
const LogareAdmin = React.lazy(()=> import('./admin/LogareAdmin'));
const OrdersList = React.lazy(()=> import('./admin/OrdersList'));
const PaginiLegal = React.lazy(()=> import('./admin/PaginiLegal'));
const ProductList = React.lazy(()=> import('./admin/ProductList'));
const UserList = React.lazy(()=> import('./admin/UserList'));
const CategoryList = React.lazy(()=> import('./admin/CategoryList'));


function App() {
  let cookieSite = Cookies.get('cookie_sesion') !== undefined ? Cookies.get('cookie_sesion') : localStorage.getItem('cookie_sesion');
  let adminCookie = Cookies.get('cookie_sesion_admin') !== undefined ? Cookies.get('cookie_sesion_admin') : localStorage.getItem('cookie_sesion_admin');
  const dispatch = useDispatch();
  const cart = useSelector(state => state.ecommerce_cart?.cart);
  useEffect(() => {
    dispatch(cartTotals());
    localStorage.setItem('ecommerce_shop_cart', JSON.stringify(cart));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  return (
      <Suspense fallback={
        <div style={{width:'100%',height:'100vh',backgroundColor:'#fff',display:'flex',justifyContent:'center',alignItems:'center'}}>
          <svg version="1.1" id="loader-1" x="0px" y="0px" width="70px" height="70px" viewBox="0 0 40 40" enableBackground="new 0 0 40 40" xmlSpace="preserve">
              <path opacity="0.2" fill="#4d61fc" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
              <path fill="#4d61fc" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                C22.32,8.481,24.301,9.057,26.013,10.047z">
              <animateTransform attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 20 20"
                to="360 20 20"
                dur="0.5s"
                repeatCount="indefinite"
              />
              </path>
          </svg>
        </div>
    }>
      {/* scroll top  */}
      <ScrollToTop />
      {/* routes project */}
      <Routes>
        {/* Publice */}
        <Route path="/" element={<Homepage/>}/>
        <Route path="/produse" element={<Products/>}/>
        <Route path="/produs/:slug" element={<Product/>}/>
        <Route path="/rezultate-cautare" element={<SearchResults/>}/>
        <Route path="/categorii/:slug" element={<Category/>}/>
        <Route path="/categorii" element={<CatList/>}/>
        <Route path="/thankyou/:id" element={<ThanYouPage/>}/>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <Route path="/checkout" element={<Checkout_Cart/>}/>
        <Route path="/contact" element={<Contact/>}/>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <Route path="/autentificare"  element={<UnProtectedRoute user={cookieSite}><AuthForm/></UnProtectedRoute>}/>
        <Route path="order-data-ecommerce/:id" element={<SeeOrder/>}/>
        {/* Cont User*/}
        <Route path="/contul-meu" element={<ProtectedRoute user={cookieSite}><Account/></ProtectedRoute>}/>
        <Route path="/comenzile-mele"  element={<ProtectedRoute user={cookieSite}><Orders/></ProtectedRoute>}/>
        {/* Cont Administrator */}
        <Route path="/logare-shop"  element={<UnProtectedRouteAdmin user={adminCookie}><LogareAdmin/></UnProtectedRouteAdmin>}/>

        <Route path="/adauga-produs"  element={<ProtectedRouteAdmin user={adminCookie}><CreateProduct/></ProtectedRouteAdmin>}/>
        <Route path="/editeaza-comanda/:id"  element={<ProtectedRouteAdmin user={adminCookie}><EditOrder/></ProtectedRouteAdmin>}/>
        <Route path="/dashboard"  element={<ProtectedRouteAdmin user={adminCookie}><Dashboard/></ProtectedRouteAdmin>}/>
        <Route path="/lista-categorii"  element={<ProtectedRouteAdmin user={adminCookie}><CategoryList/></ProtectedRouteAdmin>}/>
        <Route path="/editeaza-produs/:id"  element={<ProtectedRouteAdmin user={adminCookie}><EditProduct/></ProtectedRouteAdmin>}/>
        <Route path="/setarii-generale"  element={<ProtectedRouteAdmin user={adminCookie}><GeneralSettings/></ProtectedRouteAdmin>}/>
        <Route path="/lista-comenzii"  element={<ProtectedRouteAdmin user={adminCookie}><OrdersList/></ProtectedRouteAdmin>}/>
        <Route path="/pagini-config"  element={<ProtectedRouteAdmin user={adminCookie}><PaginiLegal/></ProtectedRouteAdmin>}/>
        <Route path="/lista-produse"  element={<ProtectedRouteAdmin user={adminCookie}><ProductList/></ProtectedRouteAdmin>}/>
        <Route path="/lista-utilizatorii"   element={<ProtectedRouteAdmin user={adminCookie}><UserList/></ProtectedRouteAdmin>}/>

        <Route path="*" element={<NoPage />} />

      </Routes>

    </Suspense>
  );
}

export default App;

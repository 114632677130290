import React from 'react';
import {Navigate } from 'react-router-dom';

const ProtectedRoute = ({
    user,
    redirectPath = '/',
    children
})=>{
  
    if (user === undefined || user === null) {
        return <Navigate to={redirectPath} replace />;
    }

    return children;
}
export default ProtectedRoute;

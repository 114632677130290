import { createSlice } from '@reduxjs/toolkit';

export const cartSlice = createSlice({
  name: 'ecommerce_cart',
  initialState: {
    cart: localStorage.getItem('ecommerce_shop_cart')
      ? JSON.parse(localStorage.getItem('ecommerce_shop_cart'))
      : [],
    total_items: 0,
    total_amount: 0,
    shipping_fee: 19,
  },
  reducers: {
    addToCartEcommerce: (state, action) => {
      const dataCart = action.payload;
      const tempItem = state.cart.find((item) => item.id === dataCart.id);
      if (tempItem) {
        const tempCart = state.cart.map((cartItem) => {
          if (cartItem.id === dataCart.id) {
            let newAmount = cartItem.amount + dataCart.quantity;
            if (newAmount > cartItem.stock) {
              newAmount = cartItem.stock;
            }
            return { ...cartItem, amount: newAmount };
          } else {
            return cartItem;
          }
        });
        state.cart = tempCart;
      } else {
        const newItem = {
          id: dataCart.id,
          name: dataCart.title,
          amount:dataCart.quantity,
          image: dataCart.main_image,
          slug: dataCart.slug, 
          price: dataCart.price,
          stock: dataCart.stock,
        };
        state.cart = [...state.cart, newItem];
      }
    },
    removeItemEcommerce: (state, action) => {
      const tempCart = state.cart.filter((item) => item.id !== action.payload);
      state.cart = tempCart;
    },
    emptryAllCartData: (state) => {
      state.cart = [];
    },
    toggleAmountEcommerce: (state, action) => {
      const { id, value } = action.payload;
      // eslint-disable-next-line array-callback-return
      const tempCart = state.cart.map((item) => {
        if (item.id === id) {
          if (value === 'i') {
            let newAmount = item.amount + 1;
            if (newAmount > item.stock) {
              newAmount = item.stock;
            }
            return { ...item, amount: newAmount };
          }
          if (value === 'd') {
            let newAmount = item.amount - 1;
            if (newAmount < 1) {
              newAmount = 1;
            }
            return { ...item, amount: newAmount };
          }
        } else {
          return item;
        }
      });
      state.cart = tempCart;
    },
    cartTotals: (state) => {
      const { total_items, total_amount } = state.cart.reduce(
        (total, cartItem) => {
          const { amount, price } = cartItem;
          total.total_items += amount;
          total.total_amount += price * amount;
          return total;
        },
        {
          total_items: 0,
          total_amount: 0,
        }
      );
      state.total_items = total_items;
      state.total_amount = total_amount;
    },
  },
});

export const { addToCartEcommerce, removeItemEcommerce, toggleAmountEcommerce, cartTotals,emptryAllCartData } =
  cartSlice.actions;

export default cartSlice.reducer;
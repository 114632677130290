import React from 'react';
import {Navigate } from 'react-router-dom';

const UnProtectedRoute = ({
    user,
    redirectPath = '/contul-meu',
    children
})=>{

    if (user === undefined || user === null) {
        return children;
    }
    return <Navigate to={redirectPath} replace />;
}
export default UnProtectedRoute;

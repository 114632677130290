import React from 'react';
import {Navigate } from 'react-router-dom';

const ProtectedRouteAdmin = ({
    user,
    redirectPath = '/logare-shop',
    children
})=>{
    if (user === undefined || user === null) {
        return <Navigate to={redirectPath} replace />;
    }

    return children;
}
export default ProtectedRouteAdmin;
